import "slick-carousel";
import $ from "jquery";

//読み込み後実行
$(() => {
  //fvスライダー
  $(".slickElemnt").slick({
    autoplay: true,
    arrows: false,
    fade: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    autoplaySpeed: 3000,
    appendDots: $(".spSubnav .slickDots"),
  });

  //お客様の声スライダー
  const sliderObj = {};
  $(".service__contents").each(function () {
    const dotElem = $(this).find(".slickDots");
    const target = $(this).attr("id");

    const sliderOptions = {
      autoplay: true,
      infinite: true,
      arrows: false,
      fade: false,
      dots: true,
      centerMode: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      centerPadding: "0",
      focusOnSelect: false,
      touchMove: true,
      appendDots: dotElem,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            autoplay: true,
            dots: true,
            draggable: true,
            touchMove: true,
          },
        },
      ],
    };

    //cloneの数が足りなくて左側に余白ができるので、追加cloneをいれた上で、追加分左にずらす
    $(this)
      .find(".service__voiceList")
      .on("init", function (event, slick) {
        addClone($(this));
      });

    if ($(this).find(".service__voiceItem").length <= 4) {
      sliderOptions["autoplay"] = false;
      sliderOptions["dots"] = false;
      sliderOptions["draggable"] = false;

      $(this).find(".service__voiceList").addClass("pcNoslide");
    }

    sliderObj[target] = $(this).find(".service__voiceList").slick(sliderOptions);
  });

  //サービスのタブ切り替え
  $("[data-tab-target]").on("click", function () {
    $(".service__menuTab--active").removeClass("service__menuTab--active");

    $(this).addClass("service__menuTab--active");

    const target = $(this).data("tab-target");
    $(".service__menuTabContens").attr("data-tab", target);

    //非表示になっていたスライダーをリフレッシュ
    if (sliderObj[target]) {
      $(".slick-cloned-add").remove();
      setTimeout(() => {
        sliderObj[target].slick("slickSetOption", "", "", true);
        addClone(sliderObj[target]);
      }, 10);
    }
  });

  //アコーディオン
  $(".question__title").on("click", function () {
    const parent = $(this).parents(".question__listItem");
    parent.toggleClass("question__listItem--active");
    parent.find(".question__answer").slideToggle();
  });
  $(".service__contents").each(function () {
    $(this).find(".question__title:first").trigger("click");
  });

  function addClone(sliderElem) {
    const addStart = -3;
    const addCount = 2;
    const addMax = addStart - addCount;
    let addElemWidth = 0;
    setTimeout(() => {
      for (let i = addStart; i > addMax; i--) {
        const elem = sliderElem.find(".slick-slide").eq(i).clone();
        elem.attr("data-slick-index", i).addClass("slick-cloned").addClass("slick-cloned-add");
        sliderElem.find(".slick-track").prepend(elem);
        addElemWidth += sliderElem.width();
      }
      const allWidth = sliderElem.find(".slick-slide").length * sliderElem.width();

      sliderElem.find(".slick-track").css("width", `${allWidth}px`);

      if (sliderElem.find(".slick-slide").length > 1) sliderElem.find(".slick-track").css("margin-left", `-${addElemWidth}px`);
    }, 10);
  }
});
